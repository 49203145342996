import OverviewPage from '@modules/professional/page/OverviewPage';
import { loader } from '@modules/professional/page/loaders/overview-page-loader.server';
import { getPreloadImageLink } from '~/modules/images';
import type { APHandle } from '~/modules/root';
import { getRouteData } from '~/modules/root/util/get-route-data';
import { mergeMeta } from '~/modules/seo';

export const meta = mergeMeta<typeof loader>(
    () => {
        return [];
    },
    (props) => {
        const { data, matches } = props;
        const { appData } = getRouteData(matches);
        const imageConfigProps = appData?.imageConfigProps;
        const bannerImage =
            data?.banners &&
            data.banners.length > 0 &&
            data.banners[0]?.libraryImageFile?.DetailBannerStandard?.Desktop;

        if (bannerImage) {
            if (data.deviceType === 'mobile') {
                return getPreloadImageLink(
                    imageConfigProps,
                    undefined,
                    bannerImage
                );
            }
            return getPreloadImageLink(imageConfigProps, bannerImage);
        }

        return [];
    }
);

export const handle: APHandle = {
    showBannerAndLogoMobile: true,
    isOverviewPage: true,
};

export { loader };
export default OverviewPage;
